import { match } from 'components/common/form/formValidationRules';
import { fiftyCharactersMax } from 'helpers/regex';
const JapanRegex = /^[A-Za-z0-9()_@./#&+-\s]*$/;
const JP = {
  forceSingleAddress: true,
  market: 'JP',
  enrollment: {
    form: {
      sections: {
        account: {
          fields: ['NAME_FIRST', 'NAME_LAST', 'EMAIL', 'PASSWORD'],
        },
        shipping: {
          fields: ['ZIP', 'STATE', 'CITY', 'COUNTY', 'ADDRESS1', 'ADDRESS2'],
        },
        terms: {
          fields: ['AGE', 'TERMS', 'GAIYOSHOMEN'],
        },
        bank: {
          fields: [
            // 'NAME_ON_BANK',
            'BANK_ACCOUNT',
            // 'ID_BANK',
            // 'ID_BRANCH',
            'jp-bank-info',
            // 'NAME_BANK',
            // 'NAME_BRANCH',
          ],
        },
      },
    },
  },
  inputs: {
    NAME_ON_BANK: {
      required: false,
    },
    BANK_ACCOUNT: {
      required: false,
      mask: '9999999',
    },
    NAME_BANK: {
      required: false,
    },
    NAME_BRANCH: {
      required: false,
    },
    ID_BANK: {
      required: false,
    },
    ID_BRANCH: {
      required: false,
    },
    NAME_FIRST: {
      validationChecks: [match(JapanRegex)],
    },
    NAME_LAST: {
      validationChecks: [match(JapanRegex)],
    },
    AGE: {
      labelRef: 'AGE_20',
    },
    ZIP: {
      labelRef: 'POSTAL_CODE',
    },
    STATE: {
      labelRef: 'PREFECTURE',
      validationChecks: [match(JapanRegex)],
    },
    CITY: {
      labelRef: 'MUNICIPALITY',
      validationChecks: [match(JapanRegex)],
    },
    COUNTY: {
      labelRef: 'DISTRICT',
      validationChecks: [match(JapanRegex)],
    },
    ADDRESS1: {
      labelRef: 'Street Name and Number',
      validationChecks: [match(JapanRegex)],
    },
    ADDRESS2: {
      labelRef: 'Apartment No. + Room No.',
      validationChecks: [match(JapanRegex), match(fiftyCharactersMax)],
    },
  },
};
export default JP;
